import React, {useEffect} from "react";
import {graphql, useStaticQuery} from "gatsby";
import './Faq.scss';
import {t} from "../../i18n";

const Faq = (props) => {
    const data = useStaticQuery(graphql`
		query Faq {
            allContentfulFaqItem {
                edges {
                    node {
                        question
                        answer {
                           answer
                        }
                        node_locale
                    }
                }
            }
        }
	`);

    const faq =
        (<section className="bg bg-white">
            <div className="container scrollSpy" id="faq">
                <h1 className="title">FAQ</h1>
                <h2 className="subtitle">{t('faq.subtitle')}</h2>
                <div id="accordion" className="content accordion faq__content">
                    {data.allContentfulFaqItem.edges.filter(node => node.node.node_locale === props.locale).map((node, index) => (
                        <div key={index} className="item item-4">
                            <div className={'accordion-header target-' + index}>
                                <span className="question">{node.node.question}</span>
                            </div>
                            <div className={'accordion-body target-' + index}>
                                <div className="answer">
                                    {node.node.answer.answer}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>);

    function toggle(className) {
        document.querySelectorAll(`.${className}`).forEach((target, index) => {
            if (target) {
                if (target.classList.contains('active')) {
                    if (index % 2 === 0) {
                        setTimeout(() => {
                            target.classList.remove('active');
                        }, 500);
                    } else {
                        target.classList.remove('active');
                    }
                } else {
                    target.classList.add('active');
                }
            }
        });
    }

    useEffect(() => {
        document.querySelectorAll('.question').forEach((element, index) => {
            element.addEventListener('click', () => toggle(`target-${index}`));
        });
    }, []);

    return (faq);
};

export default Faq;
