import React from "react";
import './Header.scss';
import mainLogo from '../../images/dohack-main-logo.png';
import {graphql, Link, useStaticQuery} from "gatsby";
import useEvent from "../../hooks/useEvent";
import {t} from "../../i18n";

const Header = () => {
    const data = useStaticQuery(graphql`
        query MyQuery {
            site {
				siteMetadata {
				    portalOnline
				    currentEvent
				}
			}
        }
    `);
    const [event] = useEvent();

    return (
        <header className="silhouette has-static-nav">
            <div className="container">
                <div className="header content content-col">
                    <div className="item">
                        <img className="header__logo" src={mainLogo} alt="DO!Hack Logo"/>
                    </div>
                    {event.coHost &&
                    <div className="item">
                        <div className="header__sponsored header__centered">
                            <span className="header__sponsored-by"> co-hosted by </span>
                            <img className="header__sponsor-logo"
                                src={event.coHost.logo.localFile.base.indexOf('.svg') !== -1 ? event.coHost.logo.localFile.publicURL : event.coHost.logo.localFile.childImageSharp.fluid.src}
                                alt={event.coHost.name}/>
                        </div>
                    </div>}
                    <div className="item">
                        <h1 className="header__date">{event.startDate} - {event.endDate} {event.year} in {event.location.city}</h1>
                    </div>
                    <div className="item">
                        {data.site.siteMetadata.currentEvent === event.name ?
                            (data.site.siteMetadata.portalOnline ?
                                <a href="https://portal.dohack.io/account/register" className="btn header-btn link">{t('header.register')}</a> :
                                <Link to={`/${event.node_locale}/sponsorship`} className="btn header-btn link">{t('header.becomeSponsor')}</Link>) :
                            <Link to={`/${event.node_locale}/`} className="btn header-btn link">{t('header.toCurrentEvent')}</Link>
                        }
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;
