import React from "react";
import './Map.scss'
import MapContainer from "./MapContainer";
import useEvent from "../../hooks/useEvent";

const Map = () => {
    const [event] = useEvent();
    return (
        <section className="location scrollSpy" id="location">
            <MapContainer eventData={event}/>
        </section>
    )
};

export default Map;





