import React from "react";
import ScheduleEntry from "../schedule-entry/ScheduleEntry";
import './ScheduleDay.scss';

const ScheduleDay = ({data}) => {
    return (
        <div className="item item-3 schedule__card">
            <table className="schedule__timetable">
                <thead>
                <tr>
                    <th className="schedule__timetable-cell schedule__timetable-cell--head" colSpan={2}>
                        {data.day}
                    </th>
                </tr>
                </thead>
                <tbody>
                {data.timetableEntries.map(entry => <ScheduleEntry key={entry.entry} data={entry}/>)}
                </tbody>
            </table>
        </div>);
};

export default ScheduleDay;