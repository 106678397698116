import './Schedule.scss'
import React from "react";
import ScheduleDay from "../schedule-day/ScheduleDay";
import useEvent from "../../hooks/useEvent";

const Schedule = () => {
    const [event] = useEvent();
    return (
        <section className="bg bg-orange">
            <div className="container scrollSpy" id="schedule">
                <h1 className="title">Schedule</h1>
                <div className="content schedule__content">
                    {event.schedule.map(day => <ScheduleDay key={day.day} data={day}/>)}
                </div>
            </div>
        </section>
    );
};

export default Schedule;