import React from 'react';
import {graphql} from 'gatsby'

import Navbar from '../components/navbar/Navbar';
import Header from '../components/header/Header';
import Faq from '../components/faq/Faq';
import About from '../components/about/About';
import Schedule from '../components/schedule/Schedule';
import Sponsors from '../components/sponsors/Sponsors';
import Partners from '../components/partners/Partners';
import Team from '../components/team/Team';
import Footer from '../components/footer/Footer';
import Map from "../components/map/Map";
import Scroll from "../components/navbar/Scroll";
import '../fonts/font.css';
import '../styles/style.scss';
import useEvent from "../hooks/useEvent";
import Speaker from "../components/speaker/Speaker";

const IndexPage = (props) => {
    const [event, setEvent] = useEvent();
    setEvent(props.eventData);

    return (
        <div>
            <Navbar locale={props.eventData.node_locale} currentPage={props.eventData.name}>
                {props.eventData.navigation
                    .sort((a, b) => a.order - b.order)
                    .map(navItem => <Scroll key={navItem.text} type="id" target={navItem.location} text={navItem.text}/>)
                }
            </Navbar>
            <Header />
            <About />
            <Faq locale={props.eventData.node_locale} />
            <Schedule />
            {(props.eventData.speaker) && <Speaker />}
            <Sponsors />
            <Partners />
            <Map />
            <Team />
            <Footer />
        </div>
    )
};

export default ({data}) => {
    return (<IndexPage eventData={data.allContentfulEvent.edges[0].node}/>);
};

export const data = graphql`
    query Event($eventName: String, $locale: String) {
        allContentfulEvent(filter: { name: { eq: $eventName }, node_locale: { eq: $locale }}) {
            edges {
                node {
                    aboutText {
                        content {
                            content {
                                value
                            }
                        }
                    }
                    aftermovieUrl
                    coHost {
                        name
                        url
                        logo {
                            localFile {
                                base
                                childImageSharp {
                                    fluid {
                                        src
                                    }
                                }
                                publicURL
                            }
                        }
                    }
                    endDate
                    groupImage {
                        localFile {
                            childImageSharp {
                                fluid {
                                    src
                                }
                            }
                        }
                    }
                    location {
                        city
                        country
                        lat
                        lng
                        name
                        street
                        zip
                        url
                    }
                    name
                    navigation {
                        text
                        location
                        order
                    }
                    node_locale
                    partners {
                        logo {
                            localFile {
                                base
                                childImageSharp {
                                    fluid {
                                        src
                                    }
                                }
                                publicURL
                            }
                        }
                        name
                        url
                    }
                    resume {
                        content {
                            content {
                                value
                            }
                        }
                    }
                    schedule {
                        day
                        timetableEntries {
                            entry
                            time
                        }
                    }
                    speaker {
                        description {
                            description
                        }
                        image {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        src
                                    }
                                }
                            }
                        }
                        name
                        organization
                        talkTitle
                        videoLink
                    }
                    sponsors {
                        status
                        companies {
                            name
                            url
                            logo {
                                localFile {
                                    base
                                    childImageSharp {
                                        fluid {
                                            src
                                        }
                                    }
                                    publicURL
                                }
                            }
                        }
                    }
                    startDate
                    team {
                        image {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        src
                                    }
                                }
                            }
                        }
                        name
                        links {
                            encoded
                            name
                            url
                        }
                    }
                    year
                }
            }
        }
    }
`;
