import React, {useState} from 'react';
import './Map.scss'
import {GoogleApiWrapper, InfoWindow, Map, Marker} from "google-maps-react";
import marker from '../../images/location_marker_dohack.svg'
import {t} from "../../i18n";

const mapStyle = [
    {
        featureType: "administrative",
        elementType: "all",
        stylers: [
            {
                saturation: "-100"
            }
        ]
    },
    {
        featureType: "administrative.province",
        elementType: "all",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [
            {
                saturation: -100
            },
            {
                lightness: 65
            },
            {
                visibility: "on"
            }
        ]
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [
            {
                saturation: -100
            },
            {
                lightness: "50"
            },
            {
                visibility: "simplified"
            }
        ]
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [
            {
                saturation: "-100"
            }
        ]
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified"
            }
        ]
    },
    {
        featureType: "road.arterial",
        elementType: "all",
        stylers: [
            {
                lightness: "30"
            }
        ]
    },
    {
        featureType: "road.local",
        elementType: "all",
        stylers: [
            {
                lightness: "40"
            }
        ]
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [
            {
                saturation: -100
            },
            {
                visibility: "simplified"
            }
        ]
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                hue: "#ffff00"
            },
            {
                lightness: -25
            },
            {
                saturation: -97
            }
        ]
    },
    {
        featureType: "water",
        elementType: "labels",
        stylers: [
            {
                lightness: -25
            },
            {
                saturation: -100
            }
        ]
    }
];

const MapContainer = (props) => {
    const [state, setState] = useState({
        showingInfoWindow: true,
        activeMarker: {},
        selectedPlace: {},
    });
    const location = props.eventData.location;
    const coords = {
        lat: location.lat,
        lng: location.lng
    };

    const onMarkerClick = (props, marker, e) => setState({
        showingInfoWindow: true,
        activeMarker: marker,
        selectedPlace: props
    });

   return (
       <Map google={props.google}
            initialCenter={coords}
            scrollwheel={false}
            zoom={15}
            styles={mapStyle}
       >
           <Marker
               onClick={onMarkerClick}
               name={location.name}
               position={coords}
               icon={marker}
           />
           <InfoWindow marker={state.activeMarker} visible={true}>
               <div id="content">
                   <h1 id="firstHeading" className="firstHeading">{location.name}</h1>
                   <div id="bodyContent">
                       <p>{location.street}<br/>
                           {location.zip} {location.city}, {location.country}<br/>
                           <a href={location.url} target="_blank" rel="noopener noreferrer" className="link link-orange">{t('map.moreInfo')}</a>
                       </p>
                   </div>
               </div>
           </InfoWindow>
       </Map>
   );
};

export default GoogleApiWrapper(() => {
    return {
        apiKey: "AIzaSyBFkoFaMfo_9_RtqRGmp9L1wgjqBGtfew0"
    }
})(MapContainer);
