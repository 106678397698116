import React from "react";

import './Sponsors.scss';
import LogoContainer from "../logo-container/LogoContainer";
import useEvent from "../../hooks/useEvent";

const Sponsors = () => {
    const [event] = useEvent();

    return (
        <section className="bg bg-grey">
            <div className="container scrollSpy" id="sponsors">
                <h1 className="title">Sponsors</h1>
                <div className="logo-container">
                    {event.sponsors.map(sponsorList =>
                        <div key={sponsorList.status} className="row">
                            <h1 className="sponsors__header title">{sponsorList.status}</h1>
                            <div className="content sponsors__content">
                                {sponsorList.companies.map(sponsor =>
                                    <div key={sponsor.name} className="item item-3">
                                        <LogoContainer data={sponsor} imageURL={sponsor.logo.localFile.base.indexOf('.svg') !== -1 ? sponsor.logo.localFile.publicURL : sponsor.logo.localFile.childImageSharp.fluid.src}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Sponsors;