import React from "react";
import './Team.scss';
import TeamCard from "../team-card/TeamCard";
import useEvent from "../../hooks/useEvent";

const Team = () => {
    const [event] = useEvent();

    return (
        <section className="bg bg-light-grey">
            <div className="container scrollSpy" id="team">
                <h1 className="title">Team</h1>
                <div className="content team__content">
                    {event.team.map((member, index) =>
                        <TeamCard key={index} data={member} links={['GitHub', 'GitLab', 'Twitter', 'YouTube']}
                                  imageURL={member.image.localFile.childImageSharp.fluid.src}
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

export default Team;
