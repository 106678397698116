import React from "react";

import './About.scss'
import useEvent from "../../hooks/useEvent";

const About = () => {
    const [event] = useEvent();
    return (
        <section className="bg bg-dark">
            <div className="container scrollSpy" id="about">
                <h1 className="title">About</h1>
                <div className="content about__content">
                    <div className="about__text item item-2">
                        {event.aboutText.content.map((paragraph, index) => <p key={index}>{paragraph.content[0].value}</p>)}
                    </div>
                    <div className="item item-2 about__video-container">
                        <iframe className="about__video" title="Aftermovie" src={event.aftermovieUrl}></iframe>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
