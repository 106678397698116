import React from "react";
import LogoContainer from "../logo-container/LogoContainer";
import './Partners.scss';
import useEvent from "../../hooks/useEvent";

const Partners = () => {
    const [event] = useEvent();

    return (
        <section className="bg bg-dark">
            <div className="container scrollSpy" id="partners">
                <h1 className="title">Partners</h1>
                <div className="logo-container">
                    <div className="content partner__content">
                        {event.partners.map(partner =>
                            <div key={partner.name} className="item item-5">
                                <LogoContainer data={partner} imageURL={partner.logo.localFile.base.indexOf('.svg') !== -1 ? partner.logo.localFile.publicURL : partner.logo.localFile.childImageSharp.fluid.src}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;