import useEvent from "../../hooks/useEvent";
import React, {useEffect} from "react";
import './Speaker.scss';
import {t} from "../../i18n";

const Speaker = () => {
    const [event] = useEvent();

    const speaker = (
        <section className="bg bg-light-grey">
            <div className="container scrollSpy" id="speaker">
                <h1 className="title">Speaker</h1>
                <div>
                    <div className="content speaker__content">
                        {event.speaker && event.speaker.map(speaker =>
                            <div key={speaker.name} className="item item-4">
                                <div className="speaker__item">
                                    <h2 className="speaker__talkTitle">{speaker.talkTitle}</h2>
                                    <img src={speaker.image.localFile.childImageSharp.fluid.src} alt={'Speaker Image ' + speaker.name} className="speaker__image"/>
                                    <p className="speaker__info">{speaker.name} | {speaker.organization}</p>
                                </div>
                            </div>
                        )}
                    </div>

                    <div>
                        {event.speaker && event.speaker.map((speaker, index) =>
                            <div className={`speaker__item-collapse target-speaker-${index}`}>
                                <p>{speaker.description ? speaker.description.description : 'No detailed description available.'}</p>
                                {speaker.videoLink && <a className="link link-orange" href={speaker.videoLink}>{t('speaker.viewTalk')}</a>}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );

    const toggleSpeaker = (className) => {
        const target = document.querySelector(`.${className}`);
        if (target.classList.contains('speaker__item-collapse--active')) {
            target.classList.remove('speaker__item-collapse--active');
        } else {
            const toClose = document.querySelector('.speaker__item-collapse--active');
            if (toClose) {
                toClose.classList.remove('speaker__item-collapse--active');
                setTimeout(() => {
                    target.classList.add('speaker__item-collapse--active');
                }, 500);
            } else {
                target.classList.add('speaker__item-collapse--active');
            }
        }
    }

    useEffect(() => {
        document.querySelectorAll('.speaker__item').forEach((element, index) => {
            element.addEventListener('click', () => toggleSpeaker(`target-speaker-${index}`));
        });
    }, []);

    return speaker;
};

export default Speaker;