import React from "react";
import './LogoContainer.scss';

const LogoContainer = ({data, imageURL}) => {
    return (
        <a href={data.link}>
            <img className="logo-container__logo" src={imageURL} alt={data.name}/>
        </a>);
};

export default LogoContainer;