import React from "react";
import './ScheduleEntry.scss';

const ScheduleEntry = ({data}) => {
    return (
        <tr>
            <td className="schedule__timetable-cell">{data.time}</td>
            <td className="schedule__timetable-cell" dangerouslySetInnerHTML={{__html: data.entry}}/>
        </tr>
        )
};

export default ScheduleEntry;